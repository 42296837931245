// =============================================================================
// All custom fonts and font variables to be declared here
// All font variables must have a fallback (sans-serif)
// =============================================================================
// =============================================================================
// Custom fonts: @font-face
// =============================================================================

$opensans-regular: "OpenSans-Regular", arial, sans-serif;
$opensans-light: "OpenSans-Light", arial, sans-serif;
$opensans-bold: "OpenSans-Bold", arial, sans-serif;

$glyphicons: "Glyphicons-Halflings-Regular", arial, sans-serif;

//Font-Weights
$font-wt-thin: 100;
$font-wt-regular: 400;
$font-wt-semiregular: 500;
$font-wt-semibold: 600;
$font-wt-bold: 700;
