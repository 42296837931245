.pdp-sidebar-wrapper {
    .fastbuy-wrapper {
        margin-top: 5px;
    }
}

.product-carousel {
    .owl-stage,
    .item,
    .product {
        display: flex;
        height: 100%;
    }

    .owl-nav {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        margin-top: -5%;
        display: flex;
        justify-content: space-between;

        button.owl-prev,
        button.owl-next {
            height: 54px;
            width: 54px;

            i {
                color: $white !important;
            }

            &.disabled {
                display: block;
                color: $grey3;
            }
        }
    }

    .owl-item img.swatch-circle {
        width: 1.8em;
    }

    .budget-pay-container img {
        width: 80px;
    }

    .product-tile {
        display: flex;
        flex-direction: column;
        background: $white;
        justify-content: space-between;

        .fastbuy-wrapper {
            flex: 1 0 50%;
            max-width: 50%;
        }

        .tile-body {
            text-align: center;
            position: relative;

            .link {
                color: $black;
            }

            .price {
                font-size: 15px;
                font-weight: 700;
                margin-top: 10px;

                .shoplc-price-label {
                    font-weight: 400;
                }
            }

            .promotion-msg {
                display: none;
            }

            .actions {
                .add-to-cart {
                    display: block;
                    padding: 5px;
                    color: $white;
                    background: $curious-blue;
                    text-decoration: none;
                    cursor: pointer;
                    position: relative;
                }

                .fastbuy-wrapper {
                    background-color: $la-palma;

                    a {
                        color: $white;
                        font-size: 13px;
                        font-weight: bold;
                        text-transform: uppercase;
                        text-align: center;
                        text-decoration: unset;
                        cursor: pointer;
                        display: block;
                        width: 100%;
                        padding: 5px 10px;
                    }
                }
            }
        }

        &:hover {
            .actions {
                display: block;
            }
        }
    }
}

.carousel-title {
    text-align: center;
    color: $night-rider;
    margin-bottom: 30px;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 600;

    @include media-breakpoint-up(sm) {
        font-size: 30px;
    }
}

.recommendations .product-tile-wrapper .product-tile .image-container a img {
    max-width: 180px;
    margin: 0 auto;
}

.recommendations {
    .container {
        @include media-breakpoint-up(lg) {
            width: 100% !important;
            max-width: 1440px;
            padding-right: 45px !important;
            padding-left: 45px !important;
        }
    }
}

.recently-viewed-carousel {
    .container {
        @include media-breakpoint-up(lg) {
            width: 100% !important;
            max-width: 1440px;
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
    }
}
