@import "bootstrap/scss/mixins/breakpoints";
@import "../variables";
@import "../components/carouselProduct";
@import "../base/fonts";
@import "../components/bx-slider";

.wishlist-carousel {
    &__container {
        background-color: #ffecf7;
    }

    &__title-section {
        align-items: center;

        @include media-breakpoint-up(lg) {
            align-content: start;
            padding-right: 0;
            margin-top: 90px;
        }
    }

    &__title {
        font-size: 20px;
        font-family: $opensans-bold;

        @include media-breakpoint-up(lg) {
            font-size: 18px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 22px;
        }
    }

    &__text {
        font-size: 16px;
    }

    &__heart {
        color: $red;
    }

    &__view-all {
        background-color: #fff;
        padding: 10px 20px;
        border-radius: 4px;
        text-align: center;

        @include media-breakpoint-up(md) {
            border-radius: 20px;
            margin: 0;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 45px;
        }
    }

    &__tile {
        background-color: #fff;
        margin: 0;
        min-height: fit-content;

        .remove-wishlist-btn {
            position: absolute;
            right: 0;
            font-size: 20px;
        }

        &--name {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 12px;
            height: 34px;
        }

        &--price {
            font-size: 16px;

            .clearance {
                color: #d92a1c;
            }

            .old-value {
                font-size: 12px;
                color: $gray;
                padding-top: 7px;
                padding-left: 8px;
                text-decoration: line-through;
            }
        }

        &--button {
            width: -webkit-fill-available;
            margin: 0 8px 8px 8px;
            font-size: 14px;
            border: none;
            color: #fff;
            padding: 8px 0;
            border-radius: 2px;
            background-color: #868dda;
        }
    }
}
