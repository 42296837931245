@import "../variables";
@import "../base/fonts";

.bx-wrapper {
    position: relative;
    padding: 0;
    *zoom: 1;
    -ms-touch-action: pan-y;
    touch-action: pan-y;

    img {
        max-width: 100%;
        display: block;
    }

    .bx-controls-direction a {
        position: absolute;
        color: $navy-blue;

        &.disabled {
            display: none;
        }
    }
}

.bxslider {
    margin: 0;
    padding: 0;

    /* fix flickering when used background-image instead of <img> (on Chrome) */
    -webkit-perspective: 1000;
}

ul.bxslider {
    list-style: none;
}

.bx-viewport {
    /* fix other elements on the page moving (in Chrome) */
    -webkit-transform: translateZ(0);

    @include media-breakpoint-down(sm) {
        overflow: scroll !important;
    }
}

.bx-prev,
.bx-next {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: $dusty-gray;

    &:hover {
        color: $dusty-gray;
        text-decoration: none;
    }
}

.bx-prev {
    top: 30px;

    @include media-breakpoint-up(lg) {
        top: 25px;
    }

    &::before {
        content: "\f106";
        font-family: "FontAwesome", arial, sans-serif;
        font-size: 48px;
    }
}

.bx-next {
    bottom: 0;

    &::before {
        content: "\f107";
        font-family: "FontAwesome", arial, sans-serif;
        font-size: 48px;
    }
}

.horizontal-mode {
    .bx-prev,
    .bx-next {
        position: absolute;
        left: auto;
        right: auto;
        bottom: auto;
        top: 50%;
        transform: translate(0, -50%);
    }

    .bx-prev {
        left: -20px;

        &::before {
            content: "\f104";
        }
    }

    .bx-next {
        right: -20px;

        &::before {
            content: "\f105";
        }
    }

    .bx-controls-direction {
        display: none;

        @include media-breakpoint-up(sm) {
            display: block;
        }
    }
}

.bx-wrapper.horizontal-mode,
.is-custom-slider-wrapper {
    max-width: 100% !important;
    width: 100% !important;
    padding: 0;
    position: relative;
    -ms-touch-action: pan-x;

    .recently-on-air-items-col {
        width: 210px !important;
    }

    .product-info {
        padding: 0;
        border-bottom: unset;

        .thumbnail-wrapper {
            padding: 10px;
        }
    }

    .bx-prev,
    .bx-next {
        &::before {
            content: "";
        }
    }

    .item-name {
        .product-name {
            width: 100%;
            padding: 4px 10px 0;
            font-size: 12px;
            font-weight: 400;
            color: $night-rider;
            white-space: normal;
            text-overflow: ellipsis;
            margin-bottom: 0.625em;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            min-height: 35px;
            text-align: center;

            &:hover {
                color: $navy-blue;
                text-decoration: unset;
            }
        }

        &:hover {
            text-decoration: unset;
        }
    }

    .priceBx {
        padding: 10px 5px 15px;

        .price {
            font-size: 15px;
            font-weight: 400;
            color: #333;
            text-align: center;

            .price-value {
                font-family: $opensans-bold;
            }
        }
    }

    .hover-section {
        position: absolute;
        bottom: 0;
        // display: none;
        width: 100%;
        height: 45px;
        background: $night-rider;

        &:hover {
            display: block !important;
        }

        @include media-breakpoint-down(sm) {
            display: none !important;
        }

        .buy-option {
            display: flex;
            flex-direction: row;
            width: auto;
            height: 35px;
            margin: 5px;
            justify-content: space-between;

            .buy-now {
                width: auto;
                height: 35px;
                padding: 0 4px 0 0;
                text-align: center;

                .add-to-cart-wishList {
                    background: $curious-blue;
                    padding: 8px 0;
                    @include media-breakpoint-down(md) {
                        padding: 8px 0;
                    }

                    button {
                        font-family: $opensans-bold;
                        padding: 0;
                        font-size: 13px;
                        font-weight: $font-wt-bold;
                        color: $white;
                        background: inherit;
                        border: 0;

                        &:hover {
                            text-decoration: none;
                        }

                        .cart-icon {
                            display: none;
                        }
                    }
                }
            }

            .fastbuy-wrapper {
                padding-left: 1px;
            }

            .fast-buy-button {
                line-height: 19px;

                .fast-buy-button-inner {
                    font-size: 13px !important;
                }
            }

            .fast-buy {
                width: auto;
                height: 35px;
                padding: 8px 12px;
                text-align: center;
                background: $la-palma;
                @include media-breakpoint-down(md) {
                    padding: 8px 9px;
                }

                a {
                    font-size: 13px;
                    font-weight: $font-wt-bold;
                    cursor: pointer;
                    color: $white !important;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .is-custom-hover-section {
        width: 13.1rem;
        display: none;
    }
}

.pdp-landingPage {
    .bx-wrapper {
        padding-left: 10px;

        .bx-controls-direction a {
            height: 170px;
            top: 10px;
        }

        .bx-prev {
            left: 0;

            &::before {
                content: "\f104 " !important;
            }
        }

        .bx-next {
            right: -30px;
            left: auto;

            &::before {
                content: "\f105" !important;
            }
        }
    }
}

/** --CRO bxSlider styles-- **/
.CRO_generic_color {
    .hover-section {
        .buy-option {
            .buy-now {
                .add-to-cart-wishList {
                    background: $primarybtn-color;
                }
            }

            .fast-buy {
                background: $primarybtn-color;
            }
        }
    }

    .bx-wrapper.horizontal-mode .hover-section .buy-option .buy-now .add-to-cart-wishList,
    .bx-wrapper.horizontal-mode .hover-section .buy-option .fast-buy {
        background: $primarybtn-color;
    }

    .bx-wrapper.horizontal-mode .hover-section {
        background: #dbdada;
        height: 47px;
    }
    .bx-wrapper.horizontal-mode {
        .item-name {
            .product-name {
                &:hover {
                    color: $primarybtn-color;
                    text-decoration: underline;
                }
            }
        }
    }
}

.is-custom-slider-wrapper {
    max-width: 1500px !important;
    padding-top: 1rem;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 2rem;
    display: none;

    h2 {
        text-align: center;
        padding: 15px;
        margin-bottom: 15px;
        margin-top: 10px;
        font-size: 30px;
        font-family: $opensans-bold;
        font-weight: 600;
    }

    .slick-track {
        height: fit-content;
        margin-left: 10px !important;

        .slick-slide {
            margin-right: 0.938rem;

            img {
                width: 100%;
                border: 2px solid $white-smoke2;
            }
        }
    }

    .slick-dots li button::before {
        font-size: 20px;
        color: $white-smoke2;
    }

    .is-custom-slider-carousel {
        width: 100%;
        margin: 0 auto;

        .is-custom-slider-item {
            max-width: 13.125rem;

            .slider-product-item {
                background: $white-smoke2;
                width: 100%;
                text-align: center;
                padding: 0;

                img {
                    height: 170px;
                    width: inherit;
                    margin: 0 auto;
                }
            }
        }
    }

    .slick-next,
    .slick-prev {
        display: grid;
        height: 45px;
        width: 45px;
        z-index: 0;

        @include media-breakpoint-down (xs) {
            display: none !important;
        }

        &::before {
            font-size: 45px;
            color: $price-grey;
        }
    }

    .slick-prev {
        top: 0;
        z-index: 1;
        left: 0;
        transform: translate(10%, -50%);

        &::before {
            top: -4px;
            color: white !important;
            position: relative;
            right: 4px;
        }
    }

    .slick-next {
        right: 0;
        top: 0;
        transform: translate(10%, -50%);

        &::before {
            top: -4px;
            color: white !important;
            position: relative;
            left: 4px;
        }
    }

    .hover-section {
        width: inherit;

        .buy-option {
            .fast-buy {
                width: auto;
                height: 36px !important;
                padding: 8px 12px;
                text-align: center;
                background: $la-palma;
            }

            .buy-now .add-to-cart-wishList {
                padding: 7.5px 0 !important;
            }
        }
    }
}

#homepage-SHOP_LC {
    .is-custom-slider-carousel .slick-prev,
    .is-custom-slider-carousel .slick-next {
        top: 0 !important;
        border-radius: 50% !important;
        display: grid !important;
        height: 45px !important;
        width: 45px !important;
        z-index: 1 !important;
        line-height: 50px !important;
        background: rgba(0, 0, 0, 0.6) !important;
    }
    
    .is-custom-slider-carousel .slick-prev {
        left: 0 !important;
    }

    .is-custom-slider-carousel .slick-next {
        right: 10px !important;
    }

    .is-custom-slider-wrapper .arrow-prev,
    .arrow-next {
        @include media-breakpoint-down (xs) {
            display: none !important;
        }
    }
}
