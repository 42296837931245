@import "~@sfra/scss/variables";

$mercury: #e5e5e5;

$lilac-purple: #868ce0;

/* $picton-blue: #3190e8;
$curious-blue: #2780dc; */
$picton-blue: $lilac-purple;
$curious-blue: $lilac-purple;
$trans-black: rgb(0 0 0 / 30%);
$bright-blue: #2780dc;
$picton-blue-gradient: #21619d;
$picton-blue-deep: #2374c1;
$picton-blue-dark: #004ca4;
$snow-drift: #f7f9fa;
$oslo-gray: #95989a;
$gray: #808080;
$gray2: #aaa;
$gray-4: #ccc;
$gray9: #8d8d8d;
$gray10: #1e1e2c;
$dark-gray2: #ababab;
$astral: #337ab7;
$valencia: #da3f3f;
$lochmara: #0281e2;
$very-light-grey: #cacaca;
$very-light-grey2: #cbcbcb;
$very-light-grey3:#c6c6c6;
$crimson: #ee1c26;
$venetian-red: #e3031d;
$emperor: #555;
$venice-blue: #085d98;
$la-palma: #35942c;
$la-palma2: #29a512;
$la-palma-dark: #066500;
$silver: #bbb;
$silver2: #bfbfbf;
$night-rider: #333;
$border-gray: #0000005e;
$sky-blue: #338ae0;
$twitter-blue: #00aced;
$persian-red: #d73c2c;
$dusty-gray: #999;
$dark-gray: #9d9d9d;
$navy-blue: #0083df;
$dark-blue: #286090;
$dark-blue2: #265a88;
$nobel: #999;
$nobel2: #b7b7b7;
$persian-red2: #d62a2a;
$out-of-stock-red: #d72b2a;
$persian-dark: #c9302c;
$dark-gray3: #a4a4a4;
$nobel-light: #b4b4b4;
$pigeon-post: #9cbed6;
$dove-gray: #707070;
$alto: #d9d9d9;
$alto-secondary: #ddd;
$iron: #d9dbdd;
$iron-secondary: #e8e8e9;
$desert-storm: #efefee;
$denim: #0f5eb1;
$denim2: #155fb8;
$seashell: #f1f1f1;
$lucky-point: #1c2266;
$pacific-blue: #02adc1;
$boston-blue: #318fb5;
$minicartred: #ff5858;
$smart-grey: #e6e6e6;
$smart-blue: #0182de;
$smart-blue2: #1585dc;
$alice-blue: #eff7ff;
$science-blue: #005dba;
$japanese-laurel: #008000;
$reached-green: #5dc267;
$solitude: #f3f5f7;
$solitude2: #f5f5f5;
$lavender: #d9edf7;
$pale-turquoise: #bce8f1;
$roof-terracotta: #a94442;
$pale-chestnut: #dca7a7;
$prussian-blue: #000440;
$suva-grey: #878787;
$carousel-bullet-default: #ded2d5;
$carousel-bullet-active: #0383de;
$alabaster: #f7f7f7;
$tundora: #4b4b4b;
$athens-gray: #f3f5f7;
$pink-red: #e3031d;
$light-gray: #d1d1d1;
$light-gray2: #d5d5d5;
$btn-red: #d0021b;
$white-smoke: #f4f4f4;
$white-smoke2: #fcfcfc;
$incredible-gray: #e3e3e3;
$progress-orange: #f0a92e;
$red: #f00;
$bondi-blue: #0093a5;
$terracotta: #e38f58;
$seashell-peach: #fff6f0;
$cornflower-blue: #66ade9;
$concrete: #f2f2f2;
$sonic-silver-gray: #7a7979;
$froly: #ed6485;
$gainsboro: #dfdfdf;
$Zambezi: #5a5a5a;
$light-blue: #2078c9;
$inline-gray: #2d2d2d;
$smart-gray: #7a7979;
$alert-red: #ee1d25;
$caution-red: #cc1e22;
$silver-chalice: #9e9e9e;
$silver-chaice2: #a0a0a0;
$silver-chaice3: #aaa;
$silver-chaice4: #8b8b8b;
$orange: #ffa500;
$sanguine-brown: #843534;
$whisper: #e9e9e9;
$light-yellow: #dcaf40;
$moccasin: #ffe0b5;
$sandybrown: #e7b446;
$jaffa-light: #da6e27;
$price-grey:#7a7a7a;
$promo-badge:#ad9979;
$wishlist-grey:#bdbdbd;
$border-select-gray: #ececec;
$alert-blue: #3793e8;
$soft-peach: #f2dede;
$reddish-white: #e7c3c3;
$ratings-orange: #f5bf17;
$pg-time: #443c3a;
$pg-shopitem: #bf0009;
$pg-lightgray: #f6f6f6;
$pg-modaltitle: #da4445;
$pg-cyanblue: #0080e2;
$pg-navyblue: #257fdc;
$pg-darkgray: #a9a9a9;
$pg-matterhorn: #565656;
$pg-gainsboro: #d8d8d8;
$pg-grayshade: #7c7c7c;
$pg-lightorange: #da6a28;
$pg-aliceblue: #f1f8ff;
$reminder-modaltitle: #eb1322;
$bondi-green: #2aa19e;
$guardsman-red: #bd000a;
$social-facebook: #3b5998;
$social-twitter: #1da1f2;
$social-pinterest: #cb2027;
$bidstatus-red: #c6615f;
$bidstatus-green: #5e9871;
$font-family-fontawesome: "FontAwesome";
$green1: #238300;
$green2: #196707;
$killarney: #3c763d;
$peppermint: #dff0d8;
$moss-green: #c8e5bc;
$wild-sand: #f5f5f5;
$fun-green: #006b29;
$cabaret: #d34854;
$label-yellow: #f8e71c;
$selective-yellow: #ffb400;
$notification-yellow-bg:#fdf3c0;
$notification-yellow-bd:#fce2ae;
$notification-grey-text: #573f16;
$login-red: #ac2925;
$border-red: #761c19;
$border-login-gray: #e7e7e7;
$welcome-gray: #ededed;
$gray-5: #eaeaea;
$red-gradient1: #c80204;
$red-gradient2: #d80000;
$purple-personalize: #7258e0;
$purple-dark-personalize: #5b44bd;
$purple-light: #8b91d8;
$brown-personalize: #6b545d;
$gold-light-personalize: #ddcfc2;
$blue-personalize: #577fbc;
$blue-primary: #787fde;
$gold-popup-personalize: #cabe84;
$red-reset-psd: #d62a2a;
$gold-video360: #ffca01;
$lc-red: #eb254b;
$lc-red-new: #da291c;
$light-grey: #d3d3d3;
$dark-gunmetal: #1e1e2c;
$light-green: #329f5c;
$light-steel-blue: #a0a1de;
$blue-primary: #787fde;
$menu-bg-color: #31314d;
$live-tv-red: #e43636;
$light-grey-bg:#f9f9f9;
$picton-blue-bg: #3190e8;
$light-green1: #ebf1ff;
$primarybtn-color: $night-rider;
$light-green2: #279556;
$pale-blue: #f3f3fc;
$grey-border-box: #0000001a;
$grey-border: #d6d6d6;
$pale-blue-light: #dadbf7;
$light-gray3: #3d3d48;
$border-purple: #a7a7c4;
$gray-status-bar: #b0b0c3;
$timer-red: #eb254b;
$pale-red-1: #f8e2e6;
$pale-red-2: #f9c3cd;
$modal-background: #F7F7FC;
$grey-background: #f2fff5;
$grey-light-border: #66a873;
$red-badge-color: #c63c34;
$green-light-1: #549c62;
$dark-green-1: #3fb589;
$grey-light-bg: #e9eaf4;
$grey-input-placeholder-color: #6c757d;
$dark-gray4: #616060;
$light-blue2: #72A8E4;
$black1: #2f2f2f;
$black2: #2e2e2e;
$light-grey1: #a2a2a2;
$red-bgcolor: #D53E52;
$gray-bgcolor: #848484;
$red-border-color: #C53C34;
$red-coupon-label-color: #d83c50;
$green-instock-color: #2ab865;
$red2: #d92a1c;
$yellow1: #fdf497;
$orange2: #fd5949;
$purple2: #d6249f;
$blue3: #285aeb;
$blue4: #4191df;
$border-grey1: #c4c4c4;
$border-gray2: #888;
$light-purple: #868CE0;
$light-gray4: #F5F5F5;
$green-light1: #339f5c;
$white-light1: #f7f8fe;
$blue-dark1: #1c1b1f;
$blue-dark2: #1e1d2c;
$amaranth: #eb294b;
$mine-shaft: #383838;

:root {
    --primaryColor: #333;
}
