@charset "UTF-8";
/* $picton-blue: #3190e8;
$curious-blue: #2780dc; */
:root {
  --primaryColor: #333;
}

.pdp-sidebar-wrapper .fastbuy-wrapper {
  margin-top: 5px;
}

.product-carousel .owl-stage,
.product-carousel .item,
.product-carousel .product {
  display: flex;
  height: 100%;
}
.product-carousel .owl-nav {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: -5%;
  display: flex;
  justify-content: space-between;
}
.product-carousel .owl-nav button.owl-prev,
.product-carousel .owl-nav button.owl-next {
  height: 54px;
  width: 54px;
}
.product-carousel .owl-nav button.owl-prev i,
.product-carousel .owl-nav button.owl-next i {
  color: #fff !important;
}
.product-carousel .owl-nav button.owl-prev.disabled,
.product-carousel .owl-nav button.owl-next.disabled {
  display: block;
  color: #ccc;
}
.product-carousel .owl-item img.swatch-circle {
  width: 1.8em;
}
.product-carousel .budget-pay-container img {
  width: 80px;
}
.product-carousel .product-tile {
  display: flex;
  flex-direction: column;
  background: #fff;
  justify-content: space-between;
}
.product-carousel .product-tile .fastbuy-wrapper {
  flex: 1 0 50%;
  max-width: 50%;
}
.product-carousel .product-tile .tile-body {
  text-align: center;
  position: relative;
}
.product-carousel .product-tile .tile-body .link {
  color: #000;
}
.product-carousel .product-tile .tile-body .price {
  font-size: 15px;
  font-weight: 700;
  margin-top: 10px;
}
.product-carousel .product-tile .tile-body .price .shoplc-price-label {
  font-weight: 400;
}
.product-carousel .product-tile .tile-body .promotion-msg {
  display: none;
}
.product-carousel .product-tile .tile-body .actions .add-to-cart {
  display: block;
  padding: 5px;
  color: #fff;
  background: #868ce0;
  text-decoration: none;
  cursor: pointer;
  position: relative;
}
.product-carousel .product-tile .tile-body .actions .fastbuy-wrapper {
  background-color: #35942c;
}
.product-carousel .product-tile .tile-body .actions .fastbuy-wrapper a {
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  text-decoration: unset;
  cursor: pointer;
  display: block;
  width: 100%;
  padding: 5px 10px;
}
.product-carousel .product-tile:hover .actions {
  display: block;
}

.carousel-title {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 600;
}
@media (min-width: 544px) {
  .carousel-title {
    font-size: 30px;
  }
}

.recommendations .product-tile-wrapper .product-tile .image-container a img {
  max-width: 180px;
  margin: 0 auto;
}

@media (min-width: 992px) {
  .recommendations .container {
    width: 100% !important;
    max-width: 1440px;
    padding-right: 45px !important;
    padding-left: 45px !important;
  }
}

@media (min-width: 992px) {
  .recently-viewed-carousel .container {
    width: 100% !important;
    max-width: 1440px;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

/* $picton-blue: #3190e8;
$curious-blue: #2780dc; */
:root {
  --primaryColor: #333;
}

.bx-wrapper {
  position: relative;
  padding: 0;
  *zoom: 1;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.bx-wrapper img {
  max-width: 100%;
  display: block;
}
.bx-wrapper .bx-controls-direction a {
  position: absolute;
  color: #0083df;
}
.bx-wrapper .bx-controls-direction a.disabled {
  display: none;
}

.bxslider {
  margin: 0;
  padding: 0;
  /* fix flickering when used background-image instead of <img> (on Chrome) */
  -webkit-perspective: 1000;
}

ul.bxslider {
  list-style: none;
}

.bx-viewport {
  /* fix other elements on the page moving (in Chrome) */
  -webkit-transform: translateZ(0);
}
@media (max-width: 768.98px) {
  .bx-viewport {
    overflow: scroll !important;
  }
}

.bx-prev,
.bx-next {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #999;
}
.bx-prev:hover,
.bx-next:hover {
  color: #999;
  text-decoration: none;
}

.bx-prev {
  top: 30px;
}
@media (min-width: 992px) {
  .bx-prev {
    top: 25px;
  }
}
.bx-prev::before {
  content: "";
  font-family: "FontAwesome", arial, sans-serif;
  font-size: 48px;
}

.bx-next {
  bottom: 0;
}
.bx-next::before {
  content: "";
  font-family: "FontAwesome", arial, sans-serif;
  font-size: 48px;
}

.horizontal-mode .bx-prev,
.horizontal-mode .bx-next {
  position: absolute;
  left: auto;
  right: auto;
  bottom: auto;
  top: 50%;
  transform: translate(0, -50%);
}
.horizontal-mode .bx-prev {
  left: -20px;
}
.horizontal-mode .bx-prev::before {
  content: "";
}
.horizontal-mode .bx-next {
  right: -20px;
}
.horizontal-mode .bx-next::before {
  content: "";
}
.horizontal-mode .bx-controls-direction {
  display: none;
}
@media (min-width: 544px) {
  .horizontal-mode .bx-controls-direction {
    display: block;
  }
}

.bx-wrapper.horizontal-mode,
.is-custom-slider-wrapper {
  max-width: 100% !important;
  width: 100% !important;
  padding: 0;
  position: relative;
  -ms-touch-action: pan-x;
}
.bx-wrapper.horizontal-mode .recently-on-air-items-col,
.is-custom-slider-wrapper .recently-on-air-items-col {
  width: 210px !important;
}
.bx-wrapper.horizontal-mode .product-info,
.is-custom-slider-wrapper .product-info {
  padding: 0;
  border-bottom: unset;
}
.bx-wrapper.horizontal-mode .product-info .thumbnail-wrapper,
.is-custom-slider-wrapper .product-info .thumbnail-wrapper {
  padding: 10px;
}
.bx-wrapper.horizontal-mode .bx-prev::before,
.bx-wrapper.horizontal-mode .bx-next::before,
.is-custom-slider-wrapper .bx-prev::before,
.is-custom-slider-wrapper .bx-next::before {
  content: "";
}
.bx-wrapper.horizontal-mode .item-name .product-name,
.is-custom-slider-wrapper .item-name .product-name {
  width: 100%;
  padding: 4px 10px 0;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  white-space: normal;
  text-overflow: ellipsis;
  margin-bottom: 0.625em;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 35px;
  text-align: center;
}
.bx-wrapper.horizontal-mode .item-name .product-name:hover,
.is-custom-slider-wrapper .item-name .product-name:hover {
  color: #0083df;
  text-decoration: unset;
}
.bx-wrapper.horizontal-mode .item-name:hover,
.is-custom-slider-wrapper .item-name:hover {
  text-decoration: unset;
}
.bx-wrapper.horizontal-mode .priceBx,
.is-custom-slider-wrapper .priceBx {
  padding: 10px 5px 15px;
}
.bx-wrapper.horizontal-mode .priceBx .price,
.is-custom-slider-wrapper .priceBx .price {
  font-size: 15px;
  font-weight: 400;
  color: #333;
  text-align: center;
}
.bx-wrapper.horizontal-mode .priceBx .price .price-value,
.is-custom-slider-wrapper .priceBx .price .price-value {
  font-family: "OpenSans-Bold", arial, sans-serif;
}
.bx-wrapper.horizontal-mode .hover-section,
.is-custom-slider-wrapper .hover-section {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 45px;
  background: #333;
}
.bx-wrapper.horizontal-mode .hover-section:hover,
.is-custom-slider-wrapper .hover-section:hover {
  display: block !important;
}
@media (max-width: 768.98px) {
  .bx-wrapper.horizontal-mode .hover-section,
.is-custom-slider-wrapper .hover-section {
    display: none !important;
  }
}
.bx-wrapper.horizontal-mode .hover-section .buy-option,
.is-custom-slider-wrapper .hover-section .buy-option {
  display: flex;
  flex-direction: row;
  width: auto;
  height: 35px;
  margin: 5px;
  justify-content: space-between;
}
.bx-wrapper.horizontal-mode .hover-section .buy-option .buy-now,
.is-custom-slider-wrapper .hover-section .buy-option .buy-now {
  width: auto;
  height: 35px;
  padding: 0 4px 0 0;
  text-align: center;
}
.bx-wrapper.horizontal-mode .hover-section .buy-option .buy-now .add-to-cart-wishList,
.is-custom-slider-wrapper .hover-section .buy-option .buy-now .add-to-cart-wishList {
  background: #868ce0;
  padding: 8px 0;
}
@media (max-width: 991.98px) {
  .bx-wrapper.horizontal-mode .hover-section .buy-option .buy-now .add-to-cart-wishList,
.is-custom-slider-wrapper .hover-section .buy-option .buy-now .add-to-cart-wishList {
    padding: 8px 0;
  }
}
.bx-wrapper.horizontal-mode .hover-section .buy-option .buy-now .add-to-cart-wishList button,
.is-custom-slider-wrapper .hover-section .buy-option .buy-now .add-to-cart-wishList button {
  font-family: "OpenSans-Bold", arial, sans-serif;
  padding: 0;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  background: inherit;
  border: 0;
}
.bx-wrapper.horizontal-mode .hover-section .buy-option .buy-now .add-to-cart-wishList button:hover,
.is-custom-slider-wrapper .hover-section .buy-option .buy-now .add-to-cart-wishList button:hover {
  text-decoration: none;
}
.bx-wrapper.horizontal-mode .hover-section .buy-option .buy-now .add-to-cart-wishList button .cart-icon,
.is-custom-slider-wrapper .hover-section .buy-option .buy-now .add-to-cart-wishList button .cart-icon {
  display: none;
}
.bx-wrapper.horizontal-mode .hover-section .buy-option .fastbuy-wrapper,
.is-custom-slider-wrapper .hover-section .buy-option .fastbuy-wrapper {
  padding-left: 1px;
}
.bx-wrapper.horizontal-mode .hover-section .buy-option .fast-buy-button,
.is-custom-slider-wrapper .hover-section .buy-option .fast-buy-button {
  line-height: 19px;
}
.bx-wrapper.horizontal-mode .hover-section .buy-option .fast-buy-button .fast-buy-button-inner,
.is-custom-slider-wrapper .hover-section .buy-option .fast-buy-button .fast-buy-button-inner {
  font-size: 13px !important;
}
.bx-wrapper.horizontal-mode .hover-section .buy-option .fast-buy,
.is-custom-slider-wrapper .hover-section .buy-option .fast-buy {
  width: auto;
  height: 35px;
  padding: 8px 12px;
  text-align: center;
  background: #35942c;
}
@media (max-width: 991.98px) {
  .bx-wrapper.horizontal-mode .hover-section .buy-option .fast-buy,
.is-custom-slider-wrapper .hover-section .buy-option .fast-buy {
    padding: 8px 9px;
  }
}
.bx-wrapper.horizontal-mode .hover-section .buy-option .fast-buy a,
.is-custom-slider-wrapper .hover-section .buy-option .fast-buy a {
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  color: #fff !important;
}
.bx-wrapper.horizontal-mode .hover-section .buy-option .fast-buy a:hover,
.is-custom-slider-wrapper .hover-section .buy-option .fast-buy a:hover {
  text-decoration: none;
}
.bx-wrapper.horizontal-mode .is-custom-hover-section,
.is-custom-slider-wrapper .is-custom-hover-section {
  width: 13.1rem;
  display: none;
}

.pdp-landingPage .bx-wrapper {
  padding-left: 10px;
}
.pdp-landingPage .bx-wrapper .bx-controls-direction a {
  height: 170px;
  top: 10px;
}
.pdp-landingPage .bx-wrapper .bx-prev {
  left: 0;
}
.pdp-landingPage .bx-wrapper .bx-prev::before {
  content: "" !important;
}
.pdp-landingPage .bx-wrapper .bx-next {
  right: -30px;
  left: auto;
}
.pdp-landingPage .bx-wrapper .bx-next::before {
  content: "" !important;
}

/** --CRO bxSlider styles-- **/
.CRO_generic_color .hover-section .buy-option .buy-now .add-to-cart-wishList {
  background: #333;
}
.CRO_generic_color .hover-section .buy-option .fast-buy {
  background: #333;
}
.CRO_generic_color .bx-wrapper.horizontal-mode .hover-section .buy-option .buy-now .add-to-cart-wishList,
.CRO_generic_color .bx-wrapper.horizontal-mode .hover-section .buy-option .fast-buy {
  background: #333;
}
.CRO_generic_color .bx-wrapper.horizontal-mode .hover-section {
  background: #dbdada;
  height: 47px;
}
.CRO_generic_color .bx-wrapper.horizontal-mode .item-name .product-name:hover {
  color: #333;
  text-decoration: underline;
}

.is-custom-slider-wrapper {
  max-width: 1500px !important;
  padding-top: 1rem;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 2rem;
  display: none;
}
.is-custom-slider-wrapper h2 {
  text-align: center;
  padding: 15px;
  margin-bottom: 15px;
  margin-top: 10px;
  font-size: 30px;
  font-family: "OpenSans-Bold", arial, sans-serif;
  font-weight: 600;
}
.is-custom-slider-wrapper .slick-track {
  height: fit-content;
  margin-left: 10px !important;
}
.is-custom-slider-wrapper .slick-track .slick-slide {
  margin-right: 0.938rem;
}
.is-custom-slider-wrapper .slick-track .slick-slide img {
  width: 100%;
  border: 2px solid #fcfcfc;
}
.is-custom-slider-wrapper .slick-dots li button::before {
  font-size: 20px;
  color: #fcfcfc;
}
.is-custom-slider-wrapper .is-custom-slider-carousel {
  width: 100%;
  margin: 0 auto;
}
.is-custom-slider-wrapper .is-custom-slider-carousel .is-custom-slider-item {
  max-width: 13.125rem;
}
.is-custom-slider-wrapper .is-custom-slider-carousel .is-custom-slider-item .slider-product-item {
  background: #fcfcfc;
  width: 100%;
  text-align: center;
  padding: 0;
}
.is-custom-slider-wrapper .is-custom-slider-carousel .is-custom-slider-item .slider-product-item img {
  height: 170px;
  width: inherit;
  margin: 0 auto;
}
.is-custom-slider-wrapper .slick-next,
.is-custom-slider-wrapper .slick-prev {
  display: grid;
  height: 45px;
  width: 45px;
  z-index: 0;
}
@media (max-width: 543.98px) {
  .is-custom-slider-wrapper .slick-next,
.is-custom-slider-wrapper .slick-prev {
    display: none !important;
  }
}
.is-custom-slider-wrapper .slick-next::before,
.is-custom-slider-wrapper .slick-prev::before {
  font-size: 45px;
  color: #7a7a7a;
}
.is-custom-slider-wrapper .slick-prev {
  top: 0;
  z-index: 1;
  left: 0;
  transform: translate(10%, -50%);
}
.is-custom-slider-wrapper .slick-prev::before {
  top: -4px;
  color: white !important;
  position: relative;
  right: 4px;
}
.is-custom-slider-wrapper .slick-next {
  right: 0;
  top: 0;
  transform: translate(10%, -50%);
}
.is-custom-slider-wrapper .slick-next::before {
  top: -4px;
  color: white !important;
  position: relative;
  left: 4px;
}
.is-custom-slider-wrapper .hover-section {
  width: inherit;
}
.is-custom-slider-wrapper .hover-section .buy-option .fast-buy {
  width: auto;
  height: 36px !important;
  padding: 8px 12px;
  text-align: center;
  background: #35942c;
}
.is-custom-slider-wrapper .hover-section .buy-option .buy-now .add-to-cart-wishList {
  padding: 7.5px 0 !important;
}

#homepage-SHOP_LC .is-custom-slider-carousel .slick-prev,
#homepage-SHOP_LC .is-custom-slider-carousel .slick-next {
  top: 0 !important;
  border-radius: 50% !important;
  display: grid !important;
  height: 45px !important;
  width: 45px !important;
  z-index: 1 !important;
  line-height: 50px !important;
  background: rgba(0, 0, 0, 0.6) !important;
}
#homepage-SHOP_LC .is-custom-slider-carousel .slick-prev {
  left: 0 !important;
}
#homepage-SHOP_LC .is-custom-slider-carousel .slick-next {
  right: 10px !important;
}
@media (max-width: 543.98px) {
  #homepage-SHOP_LC .is-custom-slider-wrapper .arrow-prev,
#homepage-SHOP_LC .arrow-next {
    display: none !important;
  }
}

.wishlist-carousel__container {
  background-color: #ffecf7;
}
.wishlist-carousel__title-section {
  align-items: center;
}
@media (min-width: 992px) {
  .wishlist-carousel__title-section {
    align-content: start;
    padding-right: 0;
    margin-top: 90px;
  }
}
.wishlist-carousel__title {
  font-size: 20px;
  font-family: "OpenSans-Bold", arial, sans-serif;
}
@media (min-width: 992px) {
  .wishlist-carousel__title {
    font-size: 18px;
  }
}
@media (min-width: 1200px) {
  .wishlist-carousel__title {
    font-size: 22px;
  }
}
.wishlist-carousel__text {
  font-size: 16px;
}
.wishlist-carousel__heart {
  color: #f00;
}
.wishlist-carousel__view-all {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  text-align: center;
}
@media (min-width: 769px) {
  .wishlist-carousel__view-all {
    border-radius: 20px;
    margin: 0;
  }
}
@media (min-width: 992px) {
  .wishlist-carousel__view-all {
    margin-top: 45px;
  }
}
.wishlist-carousel__tile {
  background-color: #fff;
  margin: 0;
  min-height: fit-content;
}
.wishlist-carousel__tile .remove-wishlist-btn {
  position: absolute;
  right: 0;
  font-size: 20px;
}
.wishlist-carousel__tile--name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 12px;
  height: 34px;
}
.wishlist-carousel__tile--price {
  font-size: 16px;
}
.wishlist-carousel__tile--price .clearance {
  color: #d92a1c;
}
.wishlist-carousel__tile--price .old-value {
  font-size: 12px;
  color: #808080;
  padding-top: 7px;
  padding-left: 8px;
  text-decoration: line-through;
}
.wishlist-carousel__tile--button {
  width: -webkit-fill-available;
  margin: 0 8px 8px 8px;
  font-size: 14px;
  border: none;
  color: #fff;
  padding: 8px 0;
  border-radius: 2px;
  background-color: #868dda;
}